import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/folder-to-youtube-playlist"
      }}>{`Folder to YouTube Playlist`}</a>{` is a CLI app that uploads a folder of videos downloaded with `}<inlineCode parentName="p">{`youtube-dl`}</inlineCode>{` (with info JSON written) to a YouTube playlist using Google's YouTube OAuth API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      